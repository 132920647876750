.fast-filter-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 18px;
    overflow: hidden;
    .fast-filter-block-holder {
        display: inline-block;
        width: 100%;
    }
}

.fast-filter { 
    display: inline-block;
    width: 100%;

    .slick-slider {
        .slick-list {
            overflow: visible;
        }
    }

	.fast-filter-holder { 
        display: inline-block;
        width: calc(100% + 10px);
		.filter-element { 
            float: left;
            margin-right: 10px;
            margin-bottom: 8px;
            &.small {
                .filter-element-holder { 
                    .filter-element-text { 
                        a {
                            font-size: 14px;
                        }
                    }
                }
            }
            &.active {
                .filter-element-holder { 
                    .filter-element-text { 
                        a {
                            padding: 10px 37px 10px 13px;
                            background: $brand-primary;
                            color: white;
                            pointer-events: none;
                            touch-action: none;
                        }
                    }

                    .remove-action { 
                        opacity: 1;
                        a {
                            pointer-events: all;
                            touch-action: auto;
                        }
                    }
                }
            }
			.filter-element-holder { 
                position: relative;
				.filter-element-text { 
                    display: inline-block;
                    position: relative;
                    z-index: 1;
					a { 
                        display: inline-block;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        color: $gray-base;
                        background: $bg-secondary;
                        padding: 10px 13px;
                        border-radius: 8px;
                        &:hover {
                            background: $brand-primary;
                            color: white;
                        }
					}
				}

				.remove-action { 
                    position: absolute;
                    right: 14px;
                    top: 13px;
                    z-index: 2;
                    opacity: 0;
					a { 
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        line-height: 16px;
                        border-radius: 50%;
                        background: white;
                        color: $brand-primary;
                        pointer-events: none;
                        touch-action: none;
                        text-align: center;
                        &:hover {
                            background: $brand-primary-accent;
                        }
						svg { 
                            vertical-align: middle;
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/fast_filter";
@import "../../media/mobile/includes/catalog/fast_filter";
