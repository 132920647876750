@media (max-width: $screen-sm) {
    // sm-view
    .items-slider-block {
        overflow: hidden;
    }

    .items-slider {

        .slick-slider {
            .slick-list {
                overflow: visible;
            }
        }

        .items-slider-holder {
            .catalog-item {
                .catalog-item-holder {
                    width: 260px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
