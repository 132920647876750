@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .catalog-item { 

        .catalog-item-holder {
            >.name { 
                font-size: 20px;
            }

            .image { 

            }

            .desc { 
                font-size: 14px;
                &.small {
                    font-size: 12px;
                    height: 19px;
                }
            }
        }
    }
}
