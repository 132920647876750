@media (max-width: $screen-lg) {
    // lg-md view
    .fast-filter {
        .fast-filter-holder { 
            .filter-element { 
                &.small {
                    .filter-element-holder { 
                        .filter-element-text { 
                            a {
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                    }
                }
                &.active {
                    .filter-element-holder { 
                        .filter-element-text { 
                            a {
                                padding: 10px 35px 10px 13px;
                            }
                        }
                    }
                }
                .filter-element-holder { 
                    .filter-element-text { 

                    }

                    .remove-action { 
                        right: 22px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .fast-filter {
        .fast-filter-holder { 
            .filter-element { 
                &.small {
                    .filter-element-holder { 
                        .filter-element-text { 
                            a {
                                font-size: 12px;
                            }
                        }
                    }
                }
                .filter-element-holder { 
                    .filter-element-text { 
                        a {
                            font-size: 14px;
                        }
                    }

                    .remove-action { 
                        right: 23px;
                        top: 10px;
                    }
                }
            }
        }
    }
}
