@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-detail-block {
        margin: 0 0 45px;
    }

    .detail-image { 
        .detail-image-holder { 
            grid-template-rows: 240px 240px; 
            grid-auto-rows: 240px;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .detail-image { 
        .detail-image-holder { 
            grid-template-columns: 49.3% 1fr 1fr;
            grid-template-rows: 185px 185px; 
            grid-auto-rows: 185px;
            gap: 10px 10px;
        }
    }

    .detail-info {
        margin: 0 0 70px;
        &.no-margin {
            margin: 0;
        }
        .detail-info-holder {
            .pricing { 
                margin: 0 0 10px;
                .pricing-holder { 
                    padding: 25px 25px;
                    .price { 
                        .value { 
                            font-size: 22px;
                        }

                        .note { 
                            font-size: 12px;
                        }
                    }

                    .action { 
                    
                        .btn { 
                            width: 176px;
                            height: 45px;
                            font-size: 14px;
                        }
                    }
                }
            }

            .params.uneven {
                .params-holder { 
                    .params-list { 
                        grid-template-columns: 32.5% 1fr 1fr 1fr;
                        grid-auto-rows: 203px;
                    }
                }
            } 
            .params { 
                margin: 0 0 10px;
                .params-holder {
                    .params-list {
                        gap: 10px 10px;
                        grid-auto-rows: 169px;
                        .param { 
                            .param-holder { 
                                padding: 25px 25px;
                                .icon { 
                                    margin: 0 0 30px;
                                    .icon-holder { 
                                        svg {
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }
                                }

                                .text { 
                                    .value { 
                                        font-size: 22px;
                                    }

                                    .note {
                                        font-size: 14px;
                                    }
                                }

                                .desc {
                                    .desc-holder {
                                        p {
                                            font-size: 14px;
                                            line-height: 143%;
                                            margin: 0;
                                        }
    
                                        h2, .h2 {
                                            margin: 0 0 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .column-info { 
                margin: 0 0 10px;
                .column-info-holder {
                    grid-template-columns: 1fr;
                    gap: 10px 10px; 
                    .col { 
                        .col-holder { 
                            padding: 25px 25px;
                            .text { 
                                h3, .h3 {
                                   margin: 0 0 20px; 
                                   font-size: 22px;
                                }

                                p {
                                    font-size: 14px;
                                    line-height: 143%;
                                    margin: 0 0 16px;

                                }

                                .action {
                                    margin: 19px 0 0;
                                    .btn {
                                        font-size: 14px;
                                        padding: 12px 24px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
