@media (max-width: $screen-sm) {
    // sm-view
    .common-heading {
        margin: 0 0 25px;
        .common-heading-holder {
            padding: 0 0 9px;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
