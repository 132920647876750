@media (max-width: $screen-sm) {
    // sm-view
    .common-form { 
        .common-form-holder {
            padding: 40px 0 58px;
            .heading { 
                margin: 0 0 42px;
                .text { 
                    h3, .h3 {
                        font-size: 20px;
                    }
                }

                .note { 
                    font-size: 14px;
                }
            }

            .form { 
                width: 100%;
                form {

                    .form-group { 
                        width: 100%;
                        margin-left: 0;
                        .form-col { 
                            padding: 0;
                            &.col-1 {
                                width: 100%;
                            }
    
                            &.col-2 {
                                width: 100%;
                            }
    
                        }
                    }

                    .action {
                        .agree-col {
                            position: relative;
                            left: auto;
                            top: auto;
                            transform: none;
                            font-size: 10px;
                            margin: 15px 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
