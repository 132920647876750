@media (max-width: $screen-sm) {
    // sm-view
    .catalog-root-block {
        margin: 0 0 60px;
        .catalog-root-block-holder {
            .catalog-root {
                .catalog-root-holder {
                    grid-template-columns: 1fr; 
                    grid-template-rows: 210px; 
                    grid-auto-rows: 210px;
                    gap: 15px 15px; 
                    grid-template-areas: 
                    "big"
                    ;
                }
            }
        }
    }

    .catalog-category {
        .catalog-category-holder { 
            .name-links { 
                padding: 20px;
                .name { 
                    font-size: 16px;
                }

                .sub-category { 
                    ul {
                        li {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
