.filter-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 27px;
    .filter-block-holder {
        display: inline-block;
        width: 100%;
    }
}

.filter {
    display: inline-block;
    width: 100%;
    .filter-holder {
        display: inline-block;
        width: 100%;
        .mobile-filter-open {
            display: inline-block;
            width: 100%;
            a.btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 40px;
                background: $gray-base;
                border-color: $gray-base;
                color: white;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                border-radius: 8px;
                .text {

                }
                .icon {
                    margin-left: 10px;
                    margin-top: 2px;
                }
            }
        }


        .filter-element { 
            float: left;
            margin-right: 25px;
            &.open {
                .filter-element-holder { 
                    .filter-name { 
                        a {
                            color: $gray-base;
                        }
                    }

                    .filter-contains { 
                        opacity: 1;
                        pointer-events: all;
                        touch-action: auto;
                        padding: 30px 0 0;
                        transition: all 300ms;
                    }
                }
            }
            &.left {
                .filter-element-holder { 
                    .filter-contains { 
                        left: 0;
                        transform: none;
                    }
                }
            }
            &.right {
                .filter-element-holder { 
                    .filter-contains { 
                        right: 0;
                        left: auto;
                        transform: none;
                    }
                }
            }
            .filter-element-holder { 
                position: relative;
                .filter-name { 
                    
                    a { 
                        display: inline-block;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        position: relative;
                        padding-right: 12px;
                        color: $gray-dark;
                        &:hover {
                            color: $gray-base;
                        }
                        .icon { 
                            position: absolute;
                            right: 0;
                            top: 9px;
                            svg { 
    
                                path { 
    
                                }
                            }
                        }
                    }
                }
    
                .filter-contains { 
                    width: 260px;
                    display: inline-block;
                    position: absolute;
                    z-index: 1001;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    opacity: 0;
                    pointer-events: none;
                    touch-action: none;
                    padding: 0 0 0;
                    transition: all 300ms;
                    .filter-contains-holder { 
                        display: inline-block;
                        width: 100%;
                        background: white;
                        box-sizing: border-box;
                        padding: 20px 25px;
                        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
                        border-radius: 8px;
                        .filter-options-list { 
                            display: inline-block;
                            width: 100%;
                            .filter-options-list-holder { 
    
                                .option { 
                                    float: left;
                                    width: 100%;
                                    margin: 0 0 8.5px;
                                    &:last-of-type {
                                        margin: 0;
                                    }
                                    .form-field { 
                                        margin: 0;
                                        .checkcontainer { 
                                            display: flex;
                                            flex-direction: row-reverse;
                                            justify-content: space-between;
                                            align-items: center;
                                            &.disabled {
                                                pointer-events: none;
                                                touch-action: none;
                                                .name { 
                                                    color: #E0E0E0;
                                                }

                                                .checkmark { 
                                                    background: #F9F9F9;
                                                    border-color: #F9F9F9;
                                                }
                                            }
                                            input { 
    
                                            }
    
                                            .checkmark { 
    
                                            }
    
                                            .name { 
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 140%; 
                                                color: $gray-dark;
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    
        .filter-action { 
            float: left;
            margin-left: 13px;
            button { 
                background: none;
                border: none;
                outline: none;
                font-family: Manrope;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: $gray-base;
                padding: 0;
                border-bottom: 1px solid currentColor;
                transition: all 300ms;
                &:hover {
                    color: $brand-primary;
                    border-color: transparent;
                    transition: all 300ms;
                }
            }
        }
    }
}

.mobile-menu .mobile-menu-holder .menu-body .mobile-filter-form .main-body {
    height: calc(100vh - 81px - 130px);
}

.mobile-menu .mobile-menu-holder .menu-body .mobile-filter-form .footing .footing-holder {
    height: 130px;
}

.mobile-filter-form {
    .filter { 
        box-sizing: border-box;
        padding: 0 15px;
        .filter-holder { 
    
            .filter-element { 
                width: 100%;
                margin: 0;
                border-bottom: 1px solid #EDEDED;
                &.open {
                    .filter-element-holder { 
                        .filter-name { 
                            a {
                                .icon {
                                    svg {
                                        transform: rotate(180deg);
                                        transition: all 300ms;
                                    }
                                }
                            }
                        }
                        .filter-contains { 
                            height: auto;
                            transition: all 500ms;
                        }
                    }
                }
                .filter-element-holder { 
                    display: inline-block;
                    width: 100%;
                    padding: 20px 0 17px;
                    .filter-name { 
                        
                        a { 
                            font-size: 14px;
                            min-height: 19px;
                            display: flex;
                            align-items: center;
                            position: relative;
                            box-sizing: border-box;
                            padding: 0 20px 0 0;
                            .icon { 
                                width: 12px;
                                height: 7px;
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                svg { 
                                    width: 100%;
                                    height: 100%;
                                    transition: all 300ms;
                                    path { 
    
                                    }
                                }
                            }
                        }
                    }
    
                    .filter-contains { 
                        float: left;
                        width: 100%;
                        height: 0;
                        overflow: hidden;
                        position: relative;
                        right: auto;
                        left: auto;
                        top: auto;
                        transform: none;
                        padding: 0;
                        transition: all 500ms;
                        .filter-contains-holder { 
                            box-shadow: none;
                            padding: 17px 0 0;
                            border-radius: 0;
                            .filter-options-list { 
    
                                .filter-options-list-holder { 
    
                                    .option { 
                                        margin: 0 0 10px;
                                        &:last-of-type {
                                            margin: 0;
                                        }
                                        .form-field { 
    
                                            .checkcontainer { 
    
                                                input { 
    
                                                }
    
                                                .checkmark { 
    
                                                }
    
                                                .name { 
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .filter-action {
        .action {
            margin: 0 0 9px;
            .btn {
                width: 100%;
                height: 40px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.reset {
                    border-color: $gray-base;
                    background: transparent;
                    color: $gray-base;
                }
            }
        }
    }
}


@import "../../media/tablet/includes/catalog/filter";
@import "../../media/mobile/includes/catalog/filter";
