@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .catalog-list-block {
        padding: 60px 0 9px;
        &.margin-down {
            padding: 0;
            margin: 0 0 49px;
        }
        .catalog-list-block-holder {
            .display-options { 
                .display-options-holder { 
                    .option { 
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .catalog-list {
        .catalog-list-holder {
            width: calc(100% + 10px);
            margin-left: -5px;
            .catalog-item {
                padding: 0 5px;
                margin: 0 0 21px;
            }
        }
    }
}
