.common-heading { 
	display: inline-block;
	width: 100%;
	margin: 0 0 30px;
	.common-heading-holder { 
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		width: 100%;
		border-bottom: 1px solid #EDEDED;
		padding: 0 0 20px;
		.text { 

			h1, .h1 { 
				margin: 0;
			}
		}

		.action { 
			padding: 0 0 3px;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px;
			a { 
				&:hover {
					color: $brand-primary;
				}
				.text { 
					float: left;
				}

				.icon { 
					float: left;
					width: 16px;
					height: 12px;
					margin-left: 10px;
					padding-top: 2px;
					svg { 
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}

.common-action {
	display: inline-block;
	width: 100%;
	margin: 13px 0 0;
	.common-action-holder {
		display: flex;
		justify-content: center;
		.btn {
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 12px;
			display: flex;
			width: 290px;
			height: 40px;
			align-items: center;
			justify-content: center;
			color: $gray-dark;
			border-color: $gray-dark;
			background: white;
			border-radius: 8px;
			padding: 0;
			.text {
				float: left;
			}

			.icon {
				float: left;
				margin-left: 10px;
			}
		}
	}
}

@import "../../media/tablet/includes/index/common_heading";
@import "../../media/mobile/includes/index/common_heading";
