.common-actions {
    margin: 40px 0 0;
    .pagination-block {
        display: flex;
        justify-content: flex-end;
    }
}

@import "../../media/tablet/includes/common/actions";
@import "../../media/mobile/includes/common/actions";
