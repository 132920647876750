.text-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 87px;
    .text-block-holder {
        display: inline-block;
        width: 100%;
        h2, .h2 {
            margin: 0 0 31px;
        }

        p {
            font-size: 16px;
            margin: 0 0 13px;
        }
    }
}

@import "../../media/tablet/includes/common/text";
@import "../../media/mobile/includes/common/text";
