@media (max-width: $screen-lg) {
    // lg-md view
    .common-form { 
        .common-form-holder {
            padding: 50px 0 104px;
            .heading { 
                margin: 0 0 53px;
            }

            .form { 
                width: 88%;
                form {
                    .action {
                        .agree-col {
                            font-size: 14px;
                            margin: 5px 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .common-form { 
        .common-form-holder {
            padding: 40px 0 84px;
            .heading { 
                margin: 0 0 49px;
                .text { 
                    h3, .h3 {
                        font-size: 22px;
                    }
                }

                .note { 
                    font-size: 16px;
                }
            }

            .form { 
                width: 100%;
                form {
                    .action {
                        .agree-col {
                            font-size: 12px;
                            margin: 5px 0 0;
                        }
                    }
                }
            }
        }
    }
}
