.catalog-list-block {
    display: inline-block;
    width: 100%;
    padding: 80px 0 19px;
    &.margin-down {
        padding: 0;
        margin: 0 0 65px;
    }
    .catalog-list-block-holder {
        display: inline-block;
        width: 100%;
        .display-options { 
            display: inline-block;
            width: 100%;
            margin: 3px 0 31px;
            .display-options-holder { 
                display: inline-block;
                width: 100%;
                .option { 
                    float: left;
                    font-size: 16px;
                    .name { 
                        float: left;
                        margin-right: 16px;
                    }
        
                    .value { 
                        float: left;
                        .sort { 
                            float: left;
                            margin-right: 11px;
                            &.active {
                                a {
                                    font-weight: 600;
                                    color: $gray-base;
                                }

                            }
                            &.desc {
                                a {
                                    .icon {
                                        svg {
                                            transform: rotate(180deg);
                                            transition: all 300ms;
                                        }
                                    }
                                }
                            }
                            a { 
                                display: inline-block;
                                position: relative;
                                padding-right: 11px;
                                .icon { 
                                    position: absolute;
                                    right: 0;
                                    top: 6px;
                                    svg { 
                                        width: 6px;
                                        height: 4px;
                                        transition: all 300ms;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.catalog-list {
    display: inline-block;
    width: 100%;
    .catalog-list-holder {
        display: inline-block;
        width: calc(100% + 20px);
        margin-left: -10px;
        .catalog-item {
            float: left;
            width: 50%;
            box-sizing: border-box;
            padding: 0 10px;
            margin: 0 0 28px;
        }
    }
}

@import "../../media/tablet/includes/index/catalog_list";
@import "../../media/mobile/includes/index/catalog_list";
