@media (max-width: $screen-lg) {
    // lg-md view
}

@media (max-width: $screen-md) {
    // md-view
    .common-heading { 
        .common-heading-holder { 
            .text { 

            }

            .action { 
                font-size: 14px;
            }
        }
    }
}
