.common-form { 
    display: inline-block;
    width: 100%;
    background: $bg-secondary;
	.common-form-holder {
        display: inline-block;
        width: 100%;
        padding: 60px 0 120px;
		.heading { 
            float: left;
            width: 100%;
            margin: 0 0 50px;
			.text { 
                display: inline-block;
                width: 100%;
                text-align: center;
				h3, .h3 { 

				}
			}

			.note { 
                display: inline-block;
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin: 10px 0 0;
			}
		}

		.form { 
            margin: 0 auto 0;
            width: 66.3%;
            position: relative;
			form { 

				.form-group { 
                    width: calc(100% + 15px);
                    margin-left: -7.5px;
					.form-col { 
                        padding: 0 7.5px;
						&.col-1 {
                            width: calc((100% - 215px)/2);
                        }

                        &.col-2 {
                            width: 215px;
                        }

					}
				}

                .form-field { 

                    .input { 

                        .name { 

                        }

                        .addon { 

                            svg { 

                                path { 

                                }
                            }
                        }

                        input, textarea { 
                            background: white;
                        }
                    }
                }

                .action {
                    margin: 0;
                    .agree-col {
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        top: 100%;
                        margin: 1px 0 0;
                    }
                }
                
			}
		}
	}
}

@import "../../media/tablet/includes/common/form";
@import "../../media/mobile/includes/common/form";
