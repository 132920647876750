@media (max-width: $screen-lg) {
    // lg-md view
    .text-block {
        margin: 0 0 65px;
    }
}

@media (max-width: $screen-md) {
    // md-view
    .text-block {
        margin: 0 0 44px;
        .text-block-holder {
            p {
                font-size: 14px;
                margin: 0 0 15px;
                line-height: 143%;
            }
        }
    }
}
