@media (max-width: $screen-sm) {
    // sm-view
    .catalog-item { 

        .catalog-item-holder {
            >.name { 
                font-size: 16px;
                font-weight: 500;
            }

            .image { 
                height: 91vw;
            }

            .desc {
                font-size: 12px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
