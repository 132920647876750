@media (max-width: $screen-lg) {
    // lg-md view
    .kit-grid {
        grid-template-columns: 65.3% 1fr 1fr; 
    }

    .kit-items-list { 
        .kit-items-list-holder { 
            .kit-heading { 

            }

            .kit-group { 
                .kit-group-holder { 
                    .kit-group-name { 
                        .kit-group-name-holder { 
                            padding: 14px 20px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .kits-block {
        margin: 0 0 31px;
    }

    .kit-grid {
        grid-template-columns: 50.3% 1fr 1fr; 
    }

    .kit-items-list { 
        .kit-items-list-holder { 
            .kit-heading { 
                .kit-heading-holder { 
                    .value { 
                        padding: 15px 20px;
                        .text {
                            font-size: 22px;
                        }

                        .price {
                            font-size: 14px;
                        }
                    }
                }
            }

            .kit-group { 
                .kit-group-holder { 
                    .kit-group-name { 
                        .kit-group-name-holder { 
                            padding: 15px 20px;
                            .text {
                                font-size: 20px;
                            }
                        }
                    }

                    .kit-group-contains { 
                        .kit-group-contains-holder { 
                            .kit-item { 

                                .kit-item-holder { 
    
                                    .value {
                                        padding: 14.5px 20px;
                                        .text {
                                            font-size: 14px;
                                            width: calc(100% - 33px);
                                        }

                                        .info {
                                            width: 18px;
                                            height: 18px;
                                        }

                                        svg {
                                            width: 18px;
                                            height: 18px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .info.info-popup {
        a {
            .text-popup {
                width: 396px;
                .text-popup-holder {
                    font-size: 12px;
                    line-height: 143%;
                }
            }
        }
    }
}
