.breadcrumbs {
  display: inline-block;
  width: 100%;
  margin-top: 21px;
  margin-bottom: 19px;
}
.breadcrumbs .breadcrumbs-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
  display: inline;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  margin-right: 4px;
  color: #121214;
  margin-bottom: 5px;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a {
  color: #D9D9D9;
  text-decoration: none;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a:hover {
  color: #fad379;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep {
  position: relative;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep:after {
  content: "";
  display: inline-block;
  width: 0px;
  height: 14px;
  border-right: 1px solid #D9D9D9;
  position: absolute;
  left: 0;
  top: 5px;
}

@media (max-width: 1499px) {
  .breadcrumbs {
    margin-top: 17px;
    margin-bottom: 17px;
  }
}
@media (max-width: 1025px) {
  .breadcrumbs {
    margin-top: 2px;
    margin-bottom: 10px;
  }
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    font-size: 14px;
    margin-right: 5px;
  }
}
@media (max-width: 767px) {
  .breadcrumbs {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    font-size: 12px;
    margin-right: 3px;
  }
}
.pagination ul {
  display: inline-block;
  list-style: none;
  margin: 0 -2.5px;
}
.pagination ul > li {
  float: left;
  margin: 0 2.5px;
}
.pagination ul > li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid #F9F9F9;
  background-color: #F9F9F9;
  box-sizing: border-box;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121214;
  text-decoration: none;
  border-radius: 3px;
  transition: all 300ms;
}
.pagination ul > li a:hover {
  border-color: #121214;
  background-color: #121214;
  color: white;
  transition: all 300ms;
}
.pagination ul > li.current a {
  pointer-events: none;
  touch-action: none;
  border-color: #121214;
  background-color: #121214;
  color: white;
  transition: all 300ms;
}
.pagination ul > li.middle a {
  color: #121214;
  background: none;
  border: none;
  width: auto;
  align-items: flex-end;
}
.pagination ul > li.middle a:hover {
  color: #F7B316;
  transition: all 300ms;
}
.pagination ul > li.next, .pagination ul > li.prev {
  margin: 0 3.5px;
}
.pagination ul > li.next.disabled a, .pagination ul > li.prev.disabled a {
  color: #EDEDED;
  pointer-events: none;
  touch-action: none;
}
.pagination ul > li.next a, .pagination ul > li.prev a {
  color: #121214;
  background: none;
  border: none;
  width: auto;
}
.pagination ul > li.next a svg, .pagination ul > li.prev a svg {
  width: 8px;
  height: 14px;
}
.pagination ul > li.next a:hover, .pagination ul > li.prev a:hover {
  color: #F7B316;
  transition: all 300ms;
}
.pagination ul > li.next a svg {
  transform: rotate(180deg);
}

@media (max-width: 1025px) {
  .pagination ul > li a {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .pagination ul > li a {
    width: 24px;
    height: 24px;
    font-size: 12px;
  }
  .pagination ul > li.next a svg, .pagination ul > li.prev a svg {
    width: 6px;
    height: 10px;
  }
}
.common-heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.common-heading .common-heading-holder {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid #EDEDED;
  padding: 0 0 20px;
}
.common-heading .common-heading-holder .text h1, .common-heading .common-heading-holder .text .h1 {
  margin: 0;
}
.common-heading .common-heading-holder .action {
  padding: 0 0 3px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.common-heading .common-heading-holder .action a:hover {
  color: #F7B316;
}
.common-heading .common-heading-holder .action a .text {
  float: left;
}
.common-heading .common-heading-holder .action a .icon {
  float: left;
  width: 16px;
  height: 12px;
  margin-left: 10px;
  padding-top: 2px;
}
.common-heading .common-heading-holder .action a .icon svg {
  width: 100%;
  height: 100%;
}

.common-action {
  display: inline-block;
  width: 100%;
  margin: 13px 0 0;
}
.common-action .common-action-holder {
  display: flex;
  justify-content: center;
}
.common-action .common-action-holder .btn {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  display: flex;
  width: 290px;
  height: 40px;
  align-items: center;
  justify-content: center;
  color: #7A7A7A;
  border-color: #7A7A7A;
  background: white;
  border-radius: 8px;
  padding: 0;
}
.common-action .common-action-holder .btn .text {
  float: left;
}
.common-action .common-action-holder .btn .icon {
  float: left;
  margin-left: 10px;
}

@media (max-width: 1025px) {
  .common-heading .common-heading-holder .action {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .common-heading {
    margin: 0 0 25px;
  }
  .common-heading .common-heading-holder {
    padding: 0 0 9px;
  }
}
.common-actions {
  margin: 40px 0 0;
}
.common-actions .pagination-block {
  display: flex;
  justify-content: flex-end;
}

.text-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 87px;
}
.text-block .text-block-holder {
  display: inline-block;
  width: 100%;
}
.text-block .text-block-holder h2, .text-block .text-block-holder .h2 {
  margin: 0 0 31px;
}
.text-block .text-block-holder p {
  font-size: 16px;
  margin: 0 0 13px;
}

@media (max-width: 1499px) {
  .text-block {
    margin: 0 0 65px;
  }
}
@media (max-width: 1025px) {
  .text-block {
    margin: 0 0 44px;
  }
  .text-block .text-block-holder p {
    font-size: 14px;
    margin: 0 0 15px;
    line-height: 143%;
  }
}
@media (max-width: 767px) {
  .text-block .text-block-holder p {
    font-size: 12px;
    margin: 0 0 15px;
    line-height: 142%;
  }
}
.common-form {
  display: inline-block;
  width: 100%;
  background: #F9F9F9;
}
.common-form .common-form-holder {
  display: inline-block;
  width: 100%;
  padding: 60px 0 120px;
}
.common-form .common-form-holder .heading {
  float: left;
  width: 100%;
  margin: 0 0 50px;
}
.common-form .common-form-holder .heading .text {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.common-form .common-form-holder .heading .note {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 10px 0 0;
}
.common-form .common-form-holder .form {
  margin: 0 auto 0;
  width: 66.3%;
  position: relative;
}
.common-form .common-form-holder .form form .form-group {
  width: calc(100% + 15px);
  margin-left: -7.5px;
}
.common-form .common-form-holder .form form .form-group .form-col {
  padding: 0 7.5px;
}
.common-form .common-form-holder .form form .form-group .form-col.col-1 {
  width: calc((100% - 215px) / 2);
}
.common-form .common-form-holder .form form .form-group .form-col.col-2 {
  width: 215px;
}
.common-form .common-form-holder .form form .form-field .input input, .common-form .common-form-holder .form form .form-field .input textarea {
  background: white;
}
.common-form .common-form-holder .form form .action {
  margin: 0;
}
.common-form .common-form-holder .form form .action .agree-col {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  margin: 1px 0 0;
}

@media (max-width: 1499px) {
  .common-form .common-form-holder {
    padding: 50px 0 104px;
  }
  .common-form .common-form-holder .heading {
    margin: 0 0 53px;
  }
  .common-form .common-form-holder .form {
    width: 88%;
  }
  .common-form .common-form-holder .form form .action .agree-col {
    font-size: 14px;
    margin: 5px 0 0;
  }
}
@media (max-width: 1025px) {
  .common-form .common-form-holder {
    padding: 40px 0 84px;
  }
  .common-form .common-form-holder .heading {
    margin: 0 0 49px;
  }
  .common-form .common-form-holder .heading .text h3, .common-form .common-form-holder .heading .text .h3 {
    font-size: 22px;
  }
  .common-form .common-form-holder .heading .note {
    font-size: 16px;
  }
  .common-form .common-form-holder .form {
    width: 100%;
  }
  .common-form .common-form-holder .form form .action .agree-col {
    font-size: 12px;
    margin: 5px 0 0;
  }
}
@media (max-width: 767px) {
  .common-form .common-form-holder {
    padding: 40px 0 58px;
  }
  .common-form .common-form-holder .heading {
    margin: 0 0 42px;
  }
  .common-form .common-form-holder .heading .text h3, .common-form .common-form-holder .heading .text .h3 {
    font-size: 20px;
  }
  .common-form .common-form-holder .heading .note {
    font-size: 14px;
  }
  .common-form .common-form-holder .form {
    width: 100%;
  }
  .common-form .common-form-holder .form form .form-group {
    width: 100%;
    margin-left: 0;
  }
  .common-form .common-form-holder .form form .form-group .form-col {
    padding: 0;
  }
  .common-form .common-form-holder .form form .form-group .form-col.col-1 {
    width: 100%;
  }
  .common-form .common-form-holder .form form .form-group .form-col.col-2 {
    width: 100%;
  }
  .common-form .common-form-holder .form form .action .agree-col {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    font-size: 10px;
    margin: 15px 0 0;
  }
}
.catalog-item .catalog-item-holder {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder > .name {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 10px;
}
.catalog-item .catalog-item-holder > .name a {
  color: #121214;
}
.catalog-item .catalog-item-holder > .name a:hover {
  color: #F7B316;
}
.catalog-item .catalog-item-holder .image {
  display: inline-block;
  width: 100%;
  height: 360px;
  margin: 0 0 10px;
}
.catalog-item .catalog-item-holder .image a {
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 10px;
}
.catalog-item .catalog-item-holder .image a:hover img {
  transform: scale(1.1);
  transition: all 300ms;
}
.catalog-item .catalog-item-holder .image a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 300ms;
}
.catalog-item .catalog-item-holder .desc {
  display: flex;
  justify-content: space-between;
  width: calc(100% + 15px);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  height: 22px;
  overflow: hidden;
}
.catalog-item .catalog-item-holder .desc.small {
  font-size: 14px;
}
.catalog-item .catalog-item-holder .desc .data .price, .catalog-item .catalog-item-holder .desc .data .data-line {
  float: left;
  margin-right: 15px;
}
.catalog-item .catalog-item-holder .desc .data .price .name, .catalog-item .catalog-item-holder .desc .data .data-line .name {
  color: #7A7A7A;
  float: left;
  margin-right: 4px;
}
.catalog-item .catalog-item-holder .desc .data .price .value, .catalog-item .catalog-item-holder .desc .data .data-line .value {
  float: left;
}
@media (max-width: 1025px) {
  .catalog-item .catalog-item-holder > .name {
    font-size: 20px;
  }
  .catalog-item .catalog-item-holder .desc {
    font-size: 14px;
  }
  .catalog-item .catalog-item-holder .desc.small {
    font-size: 12px;
    height: 19px;
  }
}
@media (max-width: 767px) {
  .catalog-item .catalog-item-holder > .name {
    font-size: 16px;
    font-weight: 500;
  }
  .catalog-item .catalog-item-holder .image {
    height: 91vw;
  }
  .catalog-item .catalog-item-holder .desc {
    font-size: 12px;
  }
}
.catalog-list-block {
  display: inline-block;
  width: 100%;
  padding: 80px 0 19px;
}
.catalog-list-block.margin-down {
  padding: 0;
  margin: 0 0 65px;
}
.catalog-list-block .catalog-list-block-holder {
  display: inline-block;
  width: 100%;
}
.catalog-list-block .catalog-list-block-holder .display-options {
  display: inline-block;
  width: 100%;
  margin: 3px 0 31px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder {
  display: inline-block;
  width: 100%;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option {
  float: left;
  font-size: 16px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .name {
  float: left;
  margin-right: 16px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value {
  float: left;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort {
  float: left;
  margin-right: 11px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort.active a {
  font-weight: 600;
  color: #121214;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort.desc a .icon svg {
  transform: rotate(180deg);
  transition: all 300ms;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort a {
  display: inline-block;
  position: relative;
  padding-right: 11px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort a .icon {
  position: absolute;
  right: 0;
  top: 6px;
}
.catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option .value .sort a .icon svg {
  width: 6px;
  height: 4px;
  transition: all 300ms;
}

.catalog-list {
  display: inline-block;
  width: 100%;
}
.catalog-list .catalog-list-holder {
  display: inline-block;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.catalog-list .catalog-list-holder .catalog-item {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 0 28px;
}

@media (max-width: 1025px) {
  .catalog-list-block {
    padding: 60px 0 9px;
  }
  .catalog-list-block.margin-down {
    padding: 0;
    margin: 0 0 49px;
  }
  .catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option {
    font-size: 14px;
  }
  .catalog-list .catalog-list-holder {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .catalog-list .catalog-list-holder .catalog-item {
    padding: 0 5px;
    margin: 0 0 21px;
  }
}
@media (max-width: 767px) {
  .catalog-list-block {
    padding: 41px 0 40px;
  }
  .catalog-list-block.margin-down {
    padding: 0;
    margin: 0 0 73px;
  }
  .catalog-list-block .catalog-list-block-holder .display-options {
    margin: 3px 0 26px;
  }
  .catalog-list-block .catalog-list-block-holder .display-options .display-options-holder .option {
    font-size: 12px;
  }
  .catalog-list .catalog-list-holder {
    width: 100%;
    margin: 0;
  }
  .catalog-list .catalog-list-holder .catalog-item {
    width: 100%;
    padding: 0;
    margin: 0 0 23px;
  }
  .catalog-list .catalog-list-holder .catalog-item:last-of-type {
    margin: 0;
  }
}
.work-examples-block {
  display: inline-block;
  width: 100%;
  overflow: hidden;
  padding: 50px 0 50px;
}
.work-examples-block .work-examples-block-holder {
  display: inline-block;
  width: 100%;
}

.works-slider .works-sldier-holder.slick-slider .slick-list {
  overflow: visible;
}

.work-example.big .work-example-holder {
  width: 843px;
}
.work-example .work-example-holder {
  display: inline-block;
  width: 500px;
  height: 577px;
  margin-right: 10px;
}
.work-example .work-example-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border-radius: 10px;
  overflow: hidden;
}
.work-example .work-example-holder a:hover {
  color: #F7B316;
}
.work-example .work-example-holder a:hover .image img {
  transform: scale(1.1);
  opacity: 1;
  transition: all 300ms;
}
.work-example .work-example-holder a:hover .text .text-holder .date {
  opacity: 0;
  transition: all 300ms;
}
.work-example .work-example-holder a:hover .text .text-holder .desc {
  opacity: 0;
  transition: all 300ms;
}
.work-example .work-example-holder a .image {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  background: #121214;
}
.work-example .work-example-holder a .image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  transition: all 300ms;
}
.work-example .work-example-holder a .text {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  box-sizing: border-box;
  padding: 20px;
}
.work-example .work-example-holder a .text .text-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.work-example .work-example-holder a .text .text-holder .date {
  position: absolute;
  left: 0;
  top: 6px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  transition: all 300ms;
  color: white;
}
.work-example .work-example-holder a .text .text-holder .desc {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: all 300ms;
  color: white;
}
.work-example .work-example-holder a .text .text-holder .desc .desc-holder {
  width: calc(100% - 50px);
}
.work-example .work-example-holder a .text .text-holder .desc .desc-holder small {
  display: inline-block;
  font-size: 10px;
  line-height: 0;
  transform: translateY(-4px);
}
.work-example .work-example-holder a .text .text-holder .icon {
  position: absolute;
  right: 0;
  bottom: 0;
}
.work-example .work-example-holder a .text .text-holder .icon .icon-holder {
  width: 30px;
  height: 30px;
}
.work-example .work-example-holder a .text .text-holder .icon .icon-holder svg {
  width: 100%;
  height: 100%;
}

@media (max-width: 1499px) {
  .work-examples-block {
    padding: 40px 0 42px;
  }
  .work-example .work-example-holder {
    height: 497px;
  }
}
@media (max-width: 1025px) {
  .work-examples-block {
    padding: 40px 0 35px;
  }
  .work-example .work-example-holder {
    height: 381px;
  }
  .work-example .work-example-holder a {
    font-size: 14px;
  }
  .work-example .work-example-holder a .text .text-holder .date {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .work-examples-block {
    padding: 40px 0 40px;
  }
  .work-examples-block .work-examples-block-holder .common-action {
    margin: 22px 0 0;
  }
  .work-example.big .work-example-holder {
    width: 230px;
  }
  .work-example .work-example-holder {
    height: 331px;
    width: 260px;
  }
  .work-example .work-example-holder a {
    font-size: 12px;
  }
  .work-example .work-example-holder a .text {
    padding: 13px 15px;
  }
  .work-example .work-example-holder a .text .text-holder .date {
    font-size: 16px;
  }
  .work-example .work-example-holder a .text .text-holder .icon .icon-holder {
    width: 24px;
    height: 24px;
  }
}
.kits-block {
  display: inline-block;
  width: 100%;
  margin: 79px 0 50px;
}
.kits-block .kits-block-holder {
  display: inline-block;
  width: 100%;
}
.kits-block .kits-block-holder .heading {
  display: inline-block;
  width: 100%;
  margin: 0 0 32px;
}

.kit-grid {
  display: grid;
  grid-template-columns: 49.3% 1fr 1fr;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  grid-gap: 1px;
  background: #EDEDED;
  grid-template-areas: ". . .";
  border-bottom: 1px solid #EDEDED;
  box-sizing: border-box;
}
.kit-grid .value {
  background-color: white;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding: 13px 20px;
  box-sizing: border-box;
}
.compact-kit-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 1px;
  background: #EDEDED;
}
.compact-kit-grid .name {
  width: 100%;
  background: white;
}
.compact-kit-grid .name .name-text {
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 15px;
}
.compact-kit-grid .name .name-text .text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: calc(100% - 18px);
}
.compact-kit-grid .name .name-text .info {
  width: 18px;
  height: 18px;
}
.compact-kit-grid .name .info-popup-text {
  float: left;
  width: 100%;
}
.compact-kit-grid .name .info-popup-text .text-popup {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #EDEDED;
  padding: 15px;
}
.compact-kit-grid .name .info-popup-text .text-popup .text-popup-holder {
  display: inline-block;
  width: 100%;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.compact-kit-grid .value {
  width: 100%;
  background: white;
}
.compact-kit-grid .value .value-holder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 1px;
  background: #EDEDED;
}
.compact-kit-grid .value .value-holder .kit-heading {
  display: flex;
  box-sizing: border-box;
  padding: 14px 15px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  background: white;
}
.compact-kit-grid .value .value-holder .kit-heading .kit-name {
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.compact-kit-grid .value .value-holder .kit-heading .kit-pricing {
  width: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  color: #F7B316;
}
.compact-kit-grid .value .value-holder .kit-status {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}

.kit-items-list {
  display: inline-block;
  width: 100%;
  border: 1px solid #EDEDED;
  border-bottom: none;
  box-sizing: border-box;
  border-radius: 10px 0px 0px 0px;
}
.kit-items-list .kit-items-list-holder {
  display: inline-block;
  width: 100%;
}
.kit-items-list .kit-items-list-holder .kit-heading {
  float: left;
  width: 100%;
  box-sizing: border-box;
}
.kit-items-list .kit-items-list-holder .kit-heading .kit-heading-holder .value {
  text-align: center;
  flex-wrap: wrap;
  padding: 10px 20px;
}
.kit-items-list .kit-items-list-holder .kit-heading .kit-heading-holder .value .text {
  width: 100%;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.kit-items-list .kit-items-list-holder .kit-heading .kit-heading-holder .value .price {
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #F7B316;
  margin: 1px 0 0;
}
.kit-items-list .kit-items-list-holder .kit-group {
  float: left;
  width: 100%;
}
.kit-items-list .kit-items-list-holder .kit-group.open .kit-group-holder .kit-group-name .kit-group-name-holder .icon svg {
  transform: rotate(180deg);
  transition: all 300ms;
}
.kit-items-list .kit-items-list-holder .kit-group.open .kit-group-holder .kit-group-contains {
  height: auto;
  transition: all 300ms;
  overflow: visible;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder {
  display: inline-block;
  width: 100%;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-name {
  float: left;
  width: 100%;
  background-color: #F9F9F9;
  border-bottom: 1px solid #EDEDED;
  box-sizing: border-box;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11.5px 20px;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder:hover {
  cursor: pointer;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder .text {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder .icon {
  margin-left: 15px;
  width: 12px;
  height: 8px;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder .icon svg {
  transition: all 300ms;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains {
  float: left;
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: all 300ms;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder {
  display: inline-block;
  width: 100%;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value {
  justify-content: center;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value .text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  width: calc(100% - 35px);
  text-align: left;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value .info {
  margin-left: 15px;
  width: 20px;
  height: 20px;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value .info a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value .info a .icon {
  width: 100%;
  height: 100%;
}
.info.info-popup a {
  display: block;
  position: relative;
  color: #121214;
}
.info.info-popup a:hover .text-popup, .info.info-popup a:focus .text-popup {
  opacity: 1;
  padding: 15px 0 0;
  pointer-events: all;
  touch-action: auto;
  transition: all 300ms;
}
.info.info-popup a .text-popup {
  position: absolute;
  z-index: 1001;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  width: 500px;
  height: auto;
  opacity: 0;
  transition: all 300ms;
  pointer-events: none;
  touch-action: none;
  padding: 0 0 0;
}
.info.info-popup a .text-popup .text-popup-holder {
  display: inline-block;
  width: 100%;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background: white;
  box-sizing: border-box;
  padding: 20px 25px;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.compact-kit-items-list {
  float: left;
  width: 100%;
}
.compact-kit-items-list .compact-kit-items-list-holder {
  float: left;
  width: 100%;
  border: 1px solid #EDEDED;
  border-bottom: none;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group {
  width: 100%;
  float: left;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group.open .kit-group-holder .kit-group-contains {
  height: auto;
  transition: all 300ms;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder {
  width: 100%;
  float: left;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-name {
  width: 100%;
  float: left;
  background: #F9F9F9;
  border-bottom: 1px solid #EDEDED;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder .text {
  width: calc(100% - 17px);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder .icon {
  margin-left: 5px;
  width: 12px;
  height: 7px;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder .icon .svg {
  width: 100%;
  height: 100%;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains {
  width: 100%;
  height: 0;
  overflow: hidden;
  float: left;
  transition: all 300ms;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder {
  width: 100%;
  float: left;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item {
  width: 100%;
  float: left;
  border-bottom: 1px solid #EDEDED;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item.open-info .kit-item-holder .name .name-text .info {
  background: #EDEDED;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item.open-info .kit-item-holder .name .info-popup-text {
  height: auto;
  transition: all 300ms;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item.open-info .kit-item-holder .value .value-holder .kit-heading .kit-pricing {
  height: 17px;
  opacity: 1;
  transition: all 300ms;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .name .name-text .info {
  border-radius: 50%;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .name .info-popup-text {
  height: 0px;
  overflow: hidden;
  transition: all 300ms;
}
.compact-kit-items-list .compact-kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value .value-holder .kit-heading .kit-pricing {
  height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: all 300ms;
}

@media (max-width: 1499px) {
  .kit-grid {
    grid-template-columns: 65.3% 1fr 1fr;
  }
  .kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder {
    padding: 14px 20px;
  }
}
@media (max-width: 1025px) {
  .kits-block {
    margin: 0 0 31px;
  }
  .kit-grid {
    grid-template-columns: 50.3% 1fr 1fr;
  }
  .kit-items-list .kit-items-list-holder .kit-heading .kit-heading-holder .value {
    padding: 15px 20px;
  }
  .kit-items-list .kit-items-list-holder .kit-heading .kit-heading-holder .value .text {
    font-size: 22px;
  }
  .kit-items-list .kit-items-list-holder .kit-heading .kit-heading-holder .value .price {
    font-size: 14px;
  }
  .kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder {
    padding: 15px 20px;
  }
  .kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-name .kit-group-name-holder .text {
    font-size: 20px;
  }
  .kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value {
    padding: 14.5px 20px;
  }
  .kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value .text {
    font-size: 14px;
    width: calc(100% - 33px);
  }
  .kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value .info {
    width: 18px;
    height: 18px;
  }
  .kit-items-list .kit-items-list-holder .kit-group .kit-group-holder .kit-group-contains .kit-group-contains-holder .kit-item .kit-item-holder .value svg {
    width: 18px;
    height: 18px;
  }
  .info.info-popup a .text-popup {
    width: 396px;
  }
  .info.info-popup a .text-popup .text-popup-holder {
    font-size: 12px;
    line-height: 143%;
  }
}
@media (max-width: 767px) {
  .kits-block .kits-block-holder .heading {
    margin: 0 0 19px;
  }
}
.catalog-detail-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 49px;
}
.catalog-detail-block .catalog-detail-block-holder {
  display: inline-block;
  width: 100%;
}

.detail-image {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.detail-image .detail-image-holder {
  display: grid;
  grid-template-columns: 57.8% 1fr 1fr;
  grid-template-rows: 290px 290px;
  grid-auto-rows: 290px;
  gap: 20px 20px;
  grid-template-areas: "big . ." "big . .";
}
.detail-image .detail-image-holder .big {
  grid-area: big;
}
.detail-image .detail-image-holder .image {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.detail-image .detail-image-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.detail-image .detail-image-holder .image .image-holder img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.detail-image .detail-image-holder .image .image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.detail-info {
  display: inline-block;
  width: 100%;
}
.detail-info .detail-info-holder {
  display: inline-block;
  width: 100%;
}
.detail-info .detail-info-holder .pricing {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  background-color: #F9F9F9;
  margin: 0 0 20px;
}
.detail-info .detail-info-holder .pricing .pricing-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 25px 25px;
}
.detail-info .detail-info-holder .pricing .pricing-holder .price .value {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.detail-info .detail-info-holder .pricing .pricing-holder .price .note {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #7A7A7A;
  margin: 5px 0 0;
}
.detail-info .detail-info-holder .pricing .pricing-holder .action .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 194px;
  height: 55px;
  border-radius: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.detail-info .detail-info-holder .params.uneven .params-holder .params-list {
  grid-template-columns: 40.9% 1fr 1fr 1fr;
}
.detail-info .detail-info-holder .params {
  display: inline-block;
  width: 100%;
  margin: 0 0 20px;
}
.detail-info .detail-info-holder .params .params-holder {
  display: inline-block;
  width: 100%;
}
.detail-info .detail-info-holder .params .params-holder .params-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 183px;
  gap: 20px 20px;
}
.detail-info .detail-info-holder .params .params-holder .params-list .param {
  width: 100%;
  height: 100%;
}
.detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder {
  width: 100%;
  height: 100%;
  background: #F9F9F9;
  border-radius: 10px;
  padding: 25px 30px;
  box-sizing: border-box;
}
.detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .icon {
  width: 100%;
  margin: 0 0 23px;
}
.detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .icon .icon-holder svg {
  width: 50px;
  height: 50px;
}
.detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .text {
  width: 100%;
}
.detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .text .value {
  width: 100%;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .text .note {
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .desc .desc-holder p {
  font-size: 16px;
  color: #7A7A7A;
  margin: 0 0 1px;
}
.detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .desc .desc-holder h2, .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .desc .desc-holder .h2 {
  margin: 0 0 14px;
}
.detail-info .detail-info-holder .column-info {
  display: inline-block;
  width: 100%;
  margin: 0 0 20px;
}
.detail-info .detail-info-holder .column-info .column-info-holder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  gap: 20px 20px;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col {
  width: 100%;
  height: 100%;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder {
  width: 100%;
  height: 100%;
  background: #F9F9F9;
  border-radius: 10px;
  padding: 30px 30px;
  box-sizing: border-box;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text {
  width: 100%;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text h3, .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text .h3 {
  margin: 0 0 30px;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text p {
  font-size: 16px;
  margin: 0 0 14px;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text p:last-of-type {
  margin: 0;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text .action {
  width: 100%;
  margin: 28px 0 0;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text .action .btn {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 15px 25px;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .image {
  width: 100%;
  height: 100%;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .image a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .image a img {
  width: 438px;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1499px) {
  .catalog-detail-block {
    margin: 0 0 45px;
  }
  .detail-image .detail-image-holder {
    grid-template-rows: 240px 240px;
    grid-auto-rows: 240px;
  }
}
@media (max-width: 1025px) {
  .detail-image .detail-image-holder {
    grid-template-columns: 49.3% 1fr 1fr;
    grid-template-rows: 185px 185px;
    grid-auto-rows: 185px;
    gap: 10px 10px;
  }
  .detail-info {
    margin: 0 0 70px;
  }
  .detail-info.no-margin {
    margin: 0;
  }
  .detail-info .detail-info-holder .pricing {
    margin: 0 0 10px;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder {
    padding: 25px 25px;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder .price .value {
    font-size: 22px;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder .price .note {
    font-size: 12px;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder .action .btn {
    width: 176px;
    height: 45px;
    font-size: 14px;
  }
  .detail-info .detail-info-holder .params.uneven .params-holder .params-list {
    grid-template-columns: 32.5% 1fr 1fr 1fr;
    grid-auto-rows: 203px;
  }
  .detail-info .detail-info-holder .params {
    margin: 0 0 10px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list {
    gap: 10px 10px;
    grid-auto-rows: 169px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder {
    padding: 25px 25px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .icon {
    margin: 0 0 30px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .icon .icon-holder svg {
    width: 40px;
    height: 40px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .text .value {
    font-size: 22px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .text .note {
    font-size: 14px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .desc .desc-holder p {
    font-size: 14px;
    line-height: 143%;
    margin: 0;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .desc .desc-holder h2, .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .desc .desc-holder .h2 {
    margin: 0 0 15px;
  }
  .detail-info .detail-info-holder .column-info {
    margin: 0 0 10px;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder {
    grid-template-columns: 1fr;
    gap: 10px 10px;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder {
    padding: 25px 25px;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text h3, .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text .h3 {
    margin: 0 0 20px;
    font-size: 22px;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text p {
    font-size: 14px;
    line-height: 143%;
    margin: 0 0 16px;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text .action {
    margin: 19px 0 0;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text .action .btn {
    font-size: 14px;
    padding: 12px 24px;
  }
}
@media (max-width: 767px) {
  .catalog-detail-block {
    margin: 0 0 48px;
  }
  .detail-image .detail-image-holder {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 91vw 28vw;
    grid-auto-rows: 28vw;
    gap: 10px 10px;
    grid-template-areas: "big big big" ". . .";
  }
  .detail-info .detail-info-holder .pricing {
    background: none;
    margin: 0 0 30px;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder .price {
    margin: 0 0 19px;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder .price .value {
    font-size: 20px;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder .price .note {
    font-size: 10px;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder .action {
    width: 100%;
  }
  .detail-info .detail-info-holder .pricing .pricing-holder .action .btn {
    width: 100%;
    height: 40px;
    font-size: 12px;
  }
  .detail-info .detail-info-holder .params.uneven .params-holder .params-list {
    grid-template-columns: 1fr;
    grid-template-rows: 138px;
    grid-auto-rows: 74px;
    grid-template-areas: ",";
  }
  .detail-info .detail-info-holder .params {
    margin: 0 0 30px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list {
    grid-template-columns: 1fr;
    grid-auto-rows: 74px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder {
    padding: 15px 20px;
    display: flex;
    align-items: center;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .icon {
    margin: 0;
    width: 30px;
    height: 30px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .icon .icon-holder {
    width: 100%;
    height: 100%;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .icon .icon-holder svg {
    width: 100%;
    height: 100%;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .text {
    width: calc(100% - 30px);
    box-sizing: border-box;
    padding-left: 20px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .text .value {
    font-size: 20px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .text .note {
    font-size: 12px;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .desc .desc-holder p {
    font-size: 12px;
    line-height: 143%;
    margin: 0;
  }
  .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .desc .desc-holder h2, .detail-info .detail-info-holder .params .params-holder .params-list .param .param-holder .desc .desc-holder .h2 {
    margin: 0 0 15px;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col {
    margin: 0 0 8px;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder {
    background: none;
    padding: 0;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text h3, .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text .h3 {
    font-size: 20px;
    margin: 0 0 20px;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text p {
    font-size: 12px;
    line-height: 142%;
    color: #7A7A7A;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text .action {
    width: 100%;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .text .action .btn {
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding: 10px 10px;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .image {
    background: #F9F9F9;
    padding: 22px;
    box-sizing: border-box;
  }
  .detail-info .detail-info-holder .column-info .column-info-holder .col .col-holder .image a img {
    width: 100%;
  }
}
.fast-filter-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 18px;
  overflow: hidden;
}
.fast-filter-block .fast-filter-block-holder {
  display: inline-block;
  width: 100%;
}

.fast-filter {
  display: inline-block;
  width: 100%;
}
.fast-filter .slick-slider .slick-list {
  overflow: visible;
}
.fast-filter .fast-filter-holder {
  display: inline-block;
  width: calc(100% + 10px);
}
.fast-filter .fast-filter-holder .filter-element {
  float: left;
  margin-right: 10px;
  margin-bottom: 8px;
}
.fast-filter .fast-filter-holder .filter-element.small .filter-element-holder .filter-element-text a {
  font-size: 14px;
}
.fast-filter .fast-filter-holder .filter-element.active .filter-element-holder .filter-element-text a {
  padding: 10px 37px 10px 13px;
  background: #F7B316;
  color: white;
  pointer-events: none;
  touch-action: none;
}
.fast-filter .fast-filter-holder .filter-element.active .filter-element-holder .remove-action {
  opacity: 1;
}
.fast-filter .fast-filter-holder .filter-element.active .filter-element-holder .remove-action a {
  pointer-events: all;
  touch-action: auto;
}
.fast-filter .fast-filter-holder .filter-element .filter-element-holder {
  position: relative;
}
.fast-filter .fast-filter-holder .filter-element .filter-element-holder .filter-element-text {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.fast-filter .fast-filter-holder .filter-element .filter-element-holder .filter-element-text a {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121214;
  background: #F9F9F9;
  padding: 10px 13px;
  border-radius: 8px;
}
.fast-filter .fast-filter-holder .filter-element .filter-element-holder .filter-element-text a:hover {
  background: #F7B316;
  color: white;
}
.fast-filter .fast-filter-holder .filter-element .filter-element-holder .remove-action {
  position: absolute;
  right: 14px;
  top: 13px;
  z-index: 2;
  opacity: 0;
}
.fast-filter .fast-filter-holder .filter-element .filter-element-holder .remove-action a {
  display: inline-block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 50%;
  background: white;
  color: #F7B316;
  pointer-events: none;
  touch-action: none;
  text-align: center;
}
.fast-filter .fast-filter-holder .filter-element .filter-element-holder .remove-action a:hover {
  background: #fad379;
}
.fast-filter .fast-filter-holder .filter-element .filter-element-holder .remove-action a svg {
  vertical-align: middle;
}

@media (max-width: 1499px) {
  .fast-filter .fast-filter-holder .filter-element.small .filter-element-holder .filter-element-text a {
    font-size: 14px;
    font-weight: 400;
  }
  .fast-filter .fast-filter-holder .filter-element.active .filter-element-holder .filter-element-text a {
    padding: 10px 35px 10px 13px;
  }
  .fast-filter .fast-filter-holder .filter-element .filter-element-holder .remove-action {
    right: 22px;
  }
}
@media (max-width: 1025px) {
  .fast-filter .fast-filter-holder .filter-element.small .filter-element-holder .filter-element-text a {
    font-size: 12px;
  }
  .fast-filter .fast-filter-holder .filter-element .filter-element-holder .filter-element-text a {
    font-size: 14px;
  }
  .fast-filter .fast-filter-holder .filter-element .filter-element-holder .remove-action {
    right: 23px;
    top: 10px;
  }
}
@media (max-width: 767px) {
  .fast-filter-block {
    margin: 0 0 11px;
  }
  .fast-filter .fast-filter-holder .filter-element.small .filter-element-holder .filter-element-text a {
    font-size: 10px;
  }
  .fast-filter .fast-filter-holder .filter-element.active .filter-element-holder .filter-element-text a {
    padding: 10px 31px 10px 13px;
  }
  .fast-filter .fast-filter-holder .filter-element .filter-element-holder .filter-element-text a {
    font-size: 12px;
  }
  .fast-filter .fast-filter-holder .filter-element .filter-element-holder .remove-action {
    right: 23px;
    top: 12px;
  }
  .fast-filter .fast-filter-holder .filter-element .filter-element-holder .remove-action a {
    width: 12px;
    height: 12px;
    line-height: 12px;
  }
}
.filter-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 27px;
}
.filter-block .filter-block-holder {
  display: inline-block;
  width: 100%;
}

.filter {
  display: inline-block;
  width: 100%;
}
.filter .filter-holder {
  display: inline-block;
  width: 100%;
}
.filter .filter-holder .mobile-filter-open {
  display: inline-block;
  width: 100%;
}
.filter .filter-holder .mobile-filter-open a.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #121214;
  border-color: #121214;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
}
.filter .filter-holder .mobile-filter-open a.btn .icon {
  margin-left: 10px;
  margin-top: 2px;
}
.filter .filter-holder .filter-element {
  float: left;
  margin-right: 25px;
}
.filter .filter-holder .filter-element.open .filter-element-holder .filter-name a {
  color: #121214;
}
.filter .filter-holder .filter-element.open .filter-element-holder .filter-contains {
  opacity: 1;
  pointer-events: all;
  touch-action: auto;
  padding: 30px 0 0;
  transition: all 300ms;
}
.filter .filter-holder .filter-element.left .filter-element-holder .filter-contains {
  left: 0;
  transform: none;
}
.filter .filter-holder .filter-element.right .filter-element-holder .filter-contains {
  right: 0;
  left: auto;
  transform: none;
}
.filter .filter-holder .filter-element .filter-element-holder {
  position: relative;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-name a {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;
  padding-right: 12px;
  color: #7A7A7A;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-name a:hover {
  color: #121214;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-name a .icon {
  position: absolute;
  right: 0;
  top: 9px;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains {
  width: 260px;
  display: inline-block;
  position: absolute;
  z-index: 1001;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  pointer-events: none;
  touch-action: none;
  padding: 0 0 0;
  transition: all 300ms;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder {
  display: inline-block;
  width: 100%;
  background: white;
  box-sizing: border-box;
  padding: 20px 25px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list {
  display: inline-block;
  width: 100%;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option {
  float: left;
  width: 100%;
  margin: 0 0 8.5px;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option:last-of-type {
  margin: 0;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option .form-field {
  margin: 0;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option .form-field .checkcontainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option .form-field .checkcontainer.disabled {
  pointer-events: none;
  touch-action: none;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option .form-field .checkcontainer.disabled .name {
  color: #E0E0E0;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option .form-field .checkcontainer.disabled .checkmark {
  background: #F9F9F9;
  border-color: #F9F9F9;
}
.filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option .form-field .checkcontainer .name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #7A7A7A;
  margin: 0;
}
.filter .filter-holder .filter-action {
  float: left;
  margin-left: 13px;
}
.filter .filter-holder .filter-action button {
  background: none;
  border: none;
  outline: none;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #121214;
  padding: 0;
  border-bottom: 1px solid currentColor;
  transition: all 300ms;
}
.filter .filter-holder .filter-action button:hover {
  color: #F7B316;
  border-color: transparent;
  transition: all 300ms;
}

.mobile-menu .mobile-menu-holder .menu-body .mobile-filter-form .main-body {
  height: calc(100vh - 81px - 130px);
}

.mobile-menu .mobile-menu-holder .menu-body .mobile-filter-form .footing .footing-holder {
  height: 130px;
}

.mobile-filter-form .filter {
  box-sizing: border-box;
  padding: 0 15px;
}
.mobile-filter-form .filter .filter-holder .filter-element {
  width: 100%;
  margin: 0;
  border-bottom: 1px solid #EDEDED;
}
.mobile-filter-form .filter .filter-holder .filter-element.open .filter-element-holder .filter-name a .icon svg {
  transform: rotate(180deg);
  transition: all 300ms;
}
.mobile-filter-form .filter .filter-holder .filter-element.open .filter-element-holder .filter-contains {
  height: auto;
  transition: all 500ms;
}
.mobile-filter-form .filter .filter-holder .filter-element .filter-element-holder {
  display: inline-block;
  width: 100%;
  padding: 20px 0 17px;
}
.mobile-filter-form .filter .filter-holder .filter-element .filter-element-holder .filter-name a {
  font-size: 14px;
  min-height: 19px;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 20px 0 0;
}
.mobile-filter-form .filter .filter-holder .filter-element .filter-element-holder .filter-name a .icon {
  width: 12px;
  height: 7px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.mobile-filter-form .filter .filter-holder .filter-element .filter-element-holder .filter-name a .icon svg {
  width: 100%;
  height: 100%;
  transition: all 300ms;
}
.mobile-filter-form .filter .filter-holder .filter-element .filter-element-holder .filter-contains {
  float: left;
  width: 100%;
  height: 0;
  overflow: hidden;
  position: relative;
  right: auto;
  left: auto;
  top: auto;
  transform: none;
  padding: 0;
  transition: all 500ms;
}
.mobile-filter-form .filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder {
  box-shadow: none;
  padding: 17px 0 0;
  border-radius: 0;
}
.mobile-filter-form .filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option {
  margin: 0 0 10px;
}
.mobile-filter-form .filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option:last-of-type {
  margin: 0;
}
.mobile-filter-form .filter .filter-holder .filter-element .filter-element-holder .filter-contains .filter-contains-holder .filter-options-list .filter-options-list-holder .option .form-field .checkcontainer .name {
  font-size: 12px;
}
.mobile-filter-form .filter-action .action {
  margin: 0 0 9px;
}
.mobile-filter-form .filter-action .action .btn {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-filter-form .filter-action .action .btn.reset {
  border-color: #121214;
  background: transparent;
  color: #121214;
}

@media (max-width: 1499px) {
  .filter .filter-holder form .filter-element.open .filter-element-holder .filter-contains {
    padding: 10px 0 0;
  }
}
@media (max-width: 1025px) {
  .filter .filter-holder form .filter-element .filter-element-holder .filter-name a {
    font-size: 14px;
  }
  .filter .filter-holder form .filter-action button {
    font-size: 14px;
    line-height: 17px;
  }
}
@media (max-width: 767px) {
  .filter-block {
    margin: 0 0 24px;
  }
}
.items-slider-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 94px;
}
.items-slider-block .items-slider-block-holder {
  display: inline-block;
  width: 100%;
}

.items-slider .items-slider-holder {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.items-slider .items-slider-holder .catalog-item {
  box-sizing: border-box;
  padding: 0 10px;
}

@media (max-width: 1499px) {
  .items-slider-block {
    margin: 0 0 74px;
  }
}
@media (max-width: 1025px) {
  .items-slider-block {
    margin: 0 0 55px;
  }
  .items-slider .items-slider-holder {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .items-slider .items-slider-holder .catalog-item {
    padding: 0 5px;
  }
}
@media (max-width: 767px) {
  .items-slider-block {
    overflow: hidden;
  }
  .items-slider .slick-slider .slick-list {
    overflow: visible;
  }
  .items-slider .items-slider-holder .catalog-item .catalog-item-holder {
    width: 260px;
  }
}
.catalog-root-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 100px;
}
.catalog-root-block .catalog-root-block-holder {
  display: inline-block;
  width: 100%;
}
.catalog-root-block .catalog-root-block-holder .catalog-root {
  display: inline-block;
  width: 100%;
}
.catalog-root-block .catalog-root-block-holder .catalog-root .catalog-root-holder {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 315px 315px;
  grid-auto-rows: 315px;
  gap: 20px 20px;
  grid-template-areas: "big big . ." "big big . .";
}
.catalog-root-block .catalog-root-block-holder .catalog-root .catalog-root-holder .big {
  grid-area: big;
}

.catalog-category {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.catalog-category .catalog-category-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}
.catalog-category .catalog-category-holder:hover .image-link-base .image img {
  opacity: 0.65;
  transition: all 300ms;
}
.catalog-category .catalog-category-holder .image-link-base {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.catalog-category .catalog-category-holder .image-link-base .image {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #121214;
}
.catalog-category .catalog-category-holder .image-link-base .image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
  transition: all 300ms;
}
.catalog-category .catalog-category-holder .name-links {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 25px;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
}
.catalog-category .catalog-category-holder .name-links .name {
  display: inline-block;
  width: 100%;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.catalog-category .catalog-category-holder .name-links .name a {
  color: white;
}
.catalog-category .catalog-category-holder .name-links .name a:hover {
  color: #F7B316;
}
.catalog-category .catalog-category-holder .name-links .sub-category {
  display: inline-block;
  width: 100%;
  margin: 15px 0 0;
}
.catalog-category .catalog-category-holder .name-links .sub-category ul {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}
.catalog-category .catalog-category-holder .name-links .sub-category ul li {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0 0 5px;
}
.catalog-category .catalog-category-holder .name-links .sub-category ul li:last-of-type {
  margin: 0;
}
.catalog-category .catalog-category-holder .name-links .sub-category ul li a {
  color: white;
}
.catalog-category .catalog-category-holder .name-links .sub-category ul li a:hover {
  color: #F7B316;
}

@media (max-width: 1499px) {
  .catalog-root-block {
    margin: 0 0 79px;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-root .catalog-root-holder {
    grid-template-rows: 250px 250px;
    grid-auto-rows: 250px;
  }
}
@media (max-width: 1025px) {
  .catalog-root-block {
    margin: 0 0 60px;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-root .catalog-root-holder {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 195px 195px;
    grid-auto-rows: 195px;
    gap: 10px 10px;
    grid-template-areas: "big ." "big .";
  }
  .catalog-category .catalog-category-holder .image-link-base .image img {
    opacity: 0.65;
    transition: all 300ms;
  }
  .catalog-category .catalog-category-holder .name-links {
    padding: 20px;
  }
  .catalog-category .catalog-category-holder .name-links .name {
    font-size: 20px;
  }
  .catalog-category .catalog-category-holder .name-links .sub-category ul li {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .catalog-root-block {
    margin: 0 0 60px;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-root .catalog-root-holder {
    grid-template-columns: 1fr;
    grid-template-rows: 210px;
    grid-auto-rows: 210px;
    gap: 15px 15px;
    grid-template-areas: "big";
  }
  .catalog-category .catalog-category-holder .name-links {
    padding: 20px;
  }
  .catalog-category .catalog-category-holder .name-links .name {
    font-size: 16px;
  }
  .catalog-category .catalog-category-holder .name-links .sub-category ul li {
    font-size: 12px;
  }
}
.works-list {
  display: inline-block;
  width: 100%;
}
.works-list .works-list-holder {
  display: inline-block;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.works-list .works-list-holder .work-example {
  float: left;
  width: 41.7%;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 0 18px;
}
.works-list .works-list-holder .work-example.long {
  width: 58.3%;
}
.works-list .works-list-holder .work-example .work-example-holder {
  width: 100%;
  height: 577px;
}
.works-list .common-actions {
  margin: 18px 0 0;
}

@media (max-width: 1499px) {
  .works-list .works-list-holder .work-example {
    width: 55%;
  }
  .works-list .works-list-holder .work-example.long {
    width: 45%;
  }
  .works-list .common-actions {
    margin: 20px 0 0;
  }
}
@media (max-width: 1025px) {
  .works-list .works-list-holder {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .works-list .works-list-holder .work-example {
    width: 50%;
    padding: 0 5px;
    margin: 0 0 8px;
  }
  .works-list .works-list-holder .work-example.long {
    width: 50%;
  }
  .works-list .works-list-holder .work-example .work-example-holder {
    height: 400px;
  }
  .works-list .common-actions {
    margin: 28px 0 0;
  }
}
@media (max-width: 767px) {
  .works-list .works-list-holder {
    width: 100%;
    margin-left: 0;
  }
  .works-list .works-list-holder .work-example {
    width: 100%;
    padding: 0;
    margin: 0 0 13px;
  }
  .works-list .works-list-holder .work-example.long {
    width: 100%;
  }
  .works-list .works-list-holder .work-example .work-example-holder {
    height: 331px;
  }
  .works-list .common-actions {
    margin: 23px 0 0;
  }
}