.catalog-root-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 100px;
    .catalog-root-block-holder {
        display: inline-block;
        width: 100%;
        .catalog-root {
            display: inline-block;
            width: 100%;
            .catalog-root-holder {
                display: grid; 
                grid-template-columns: 1fr 1fr 1fr 1fr; 
                grid-template-rows: 315px 315px; 
                grid-auto-rows: 315px;
                gap: 20px 20px; 
                grid-template-areas: 
                  "big big . ."
                  "big big . ."
                ;
                .big {
                    grid-area: big;
                }
            }
        }
    }
}

.catalog-category { 
    display: inline-block;
    width: 100%;
    height: 100%;
	.catalog-category-holder { 
        display: inline-block;
        width: 100%;
        height: 100%;
        position: relative;
        box-sizing: border-box;
        border-radius: 10px;
        overflow: hidden;
        &:hover {
            .image-link-base { 
                .image { 
                    img { 
                        opacity: 0.65;
                        transition: all 300ms;
                    }
                }
            }
        }
		.image-link-base { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
			.image { 
                display: inline-block;
                width: 100%;
                height: 100%;
                background: $gray-base;
				img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0.4;
                    transition: all 300ms;
				}
			}
		}

		.name-links { 
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            padding: 25px;
            position: absolute;
            z-index: 2;
            left: 0;
            bottom: 0;
			.name { 
                display: inline-block;
                width: 100%;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
				a { 
                    color: white;
                    &:hover {
                        color: $brand-primary;
                    }
				}
			}

			.sub-category { 
                display: inline-block;
                width: 100%;
                margin: 15px 0 0;
				ul { 
                    display: inline-block;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                    list-style: none;
					li { 
                        display: inline-block;
                        width: 100%;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        margin: 0 0 5px;
                        &:last-of-type {
                            margin: 0;
                        }
						a { 
                            color: white;
                            &:hover {
                                color: $brand-primary;
                            }
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/root";
@import "../../media/mobile/includes/catalog/root";
