.breadcrumbs {
    display: inline-block;
    width: 100%;
    margin-top: 21px;
    margin-bottom: 19px;
    .breadcrumbs-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        ul.breadcrumbs-list {
            display: inline-block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            li {
                display: inline;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: normal;
                margin-right: 4px;
                color: $gray-base;
                margin-bottom: 5px;
                a {
                    color: #D9D9D9;
                    text-decoration: none;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-primary-accent;
                        transition: all 300ms;
                    }
                }
                span {

                }
                &.sep {
                    position: relative;
                    &:after {
                        content: "";
                        display: inline-block;
                        width: 0px;
                        height: 14px;
                        border-right: 1px solid #D9D9D9;
                        position: absolute;
                        left: 0;
                        top: 5px;
                    }

                }
            }
        }
    }
}

@import "../media/tablet/includes/breadcrumbs";
@import "../media/mobile/includes/breadcrumbs";
