@media (max-width: $screen-sm) {
    // sm-view
    .catalog-detail-block {
        margin: 0 0 48px;
    }

    .detail-image {
        .detail-image-holder { 
            grid-template-columns: 1fr 1fr 1fr; 
            grid-template-rows: 91vw 28vw; 
            grid-auto-rows: 28vw;
            gap: 10px 10px; 
            grid-template-areas: 
              "big big big"
              ". . ."; 
        }
    }

    .detail-info {
        .detail-info-holder {
            .pricing { 
                background: none;
                margin: 0 0 30px;
                .pricing-holder { 
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0;
                    .price { 
                        margin: 0 0 19px;
                        .value { 
                            font-size: 20px;
                        }

                        .note { 
                            font-size: 10px;
                        }
                    }

                    .action { 
                        width: 100%;
                        .btn {
                            width: 100%;
                            height: 40px;
                            font-size: 12px;
                        }
                    }
                }
            }

            .params.uneven {
                .params-holder { 
                    .params-list { 
                        grid-template-columns: 1fr;
                        grid-template-rows: 138px;
                        grid-auto-rows: 74px;
                        grid-template-areas: 
                            ","
                        ; 
                    }
                }
            } 
            .params { 
                margin: 0 0 30px;
                .params-holder { 
                    .params-list { 
                        grid-template-columns: 1fr;
                        grid-auto-rows: 74px;
                        .param { 
                            .param-holder { 
                                padding: 15px 20px;
                                display: flex;
                                align-items: center;
                                .icon { 
                                    margin: 0;
                                    width: 30px;
                                    height: 30px;
                                    .icon-holder {
                                        width: 100%;
                                        height: 100%;
                                        svg {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }

                                .text { 
                                    width: calc(100% - 30px);
                                    box-sizing: border-box;
                                    padding-left: 20px;
                                    .value {
                                        font-size: 20px;
                                    }

                                    .note {
                                        font-size: 12px;
                                    }
                                }

                                .desc {
                                    .desc-holder {
                                        p {
                                            font-size: 12px;
                                            line-height: 143%;
                                            margin: 0;
                                        }
    
                                        h2, .h2 {
                                            margin: 0 0 15px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .column-info { 
                .column-info-holder { 
                    .col {
                        margin: 0 0 8px;
                        .col-holder { 
                            background: none;
                            padding: 0;
                            .text { 
                                h3, .h3 { 
                                    font-size: 20px;
                                    margin: 0 0 20px;
                                }

                                p {
                                    font-size: 12px;
                                    line-height: 142%;
                                    color: $gray-dark;
                                }

                                .action {
                                    width: 100%;
                                    .btn {
                                        width: 100%;
                                        text-align: center;
                                        font-size: 12px;
                                        padding: 10px 10px;
                                    }
                                }
                            }

                            .image {
                                background: $bg-secondary;
                                padding: 22px;
                                box-sizing: border-box;
                                a {
                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
