@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-root-block {
        margin: 0 0 79px;
        .catalog-root-block-holder {
            .catalog-root {
                .catalog-root-holder {
                    grid-template-rows: 250px 250px; 
                    grid-auto-rows: 250px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .catalog-root-block {
        margin: 0 0 60px;
        .catalog-root-block-holder {
            .catalog-root {
                .catalog-root-holder {
                    grid-template-columns: 1fr 1fr; 
                    grid-template-rows: 195px 195px; 
                    grid-auto-rows: 195px;
                    gap: 10px 10px; 
                    grid-template-areas: 
                    "big ."
                    "big ."
                    ;
                }
            }
        }
    }

    .catalog-category {
        .catalog-category-holder { 
            .image-link-base { 
                .image { 
                    img { 
                        opacity: 0.65;
                        transition: all 300ms;
                    }
                }
            }
            .name-links { 
                padding: 20px;
                .name { 
                    font-size: 20px;
                }

                .sub-category { 
                    ul {
                        li {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
