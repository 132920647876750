@media (max-width: $screen-lg) {
    // lg-md view
    .filter {
        .filter-holder {
            form { 
                .filter-element { 
                    &.open {
                        .filter-element-holder { 
                            .filter-contains { 
                                padding: 10px 0 0;
                            }
                        }
                    }
                    .filter-element-holder { 
                        .filter-name { 

                        }

                        .filter-contains { 

                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .filter {
        .filter-holder {
            form { 
                .filter-element { 
                    &.open {
                        .filter-element-holder { 
                            .filter-contains { 
                                
                            }
                        }
                    }
                    .filter-element-holder { 
                        .filter-name { 

                            a {
                                font-size: 14px;
                            }

                        }

                        .filter-contains { 

                        }
                    }
                }

                .filter-action { 
                    button { 
                        font-size: 14px;
                        line-height: 17px;
                    }
                }
            }
        }
    }
}
