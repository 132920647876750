.catalog-item { 

	.catalog-item-holder {
        display: inline-block;
        width: 100%;
		>.name { 
            display: inline-block;
            width: 100%;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0 0 10px;
			a { 
                color: $gray-base;
                &:hover {
                    color: $brand-primary;
                }
			}
		}

		.image { 
            display: inline-block;
            width: 100%;
            height: 360px;
            margin: 0 0 10px;
			a { 
                display: inline-block;
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                overflow: hidden;
                border-radius: 10px;
                &:hover {
                    img {
                        transform: scale(1.1);
                        transition: all 300ms;
                    }
                }
				img { 
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 300ms;
				}
			}
		}

		.desc { 
            display: flex;
            justify-content: space-between;
            width: calc(100% + 15px);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            height: 22px;
            overflow: hidden;
            &.small {
                font-size: 14px;
            }
            .data {
                .price, .data-line { 
                    float: left;
                    margin-right: 15px;
                    .name { 
                        color: $gray-dark;
                        float: left;
                        margin-right: 4px;
                    }
    
                    .value { 
                        float: left;
                    }
                }
            }

			.date { 

			}
		}
	}
}

@import "../../media/tablet/includes/index/catalog_item";
@import "../../media/mobile/includes/index/catalog_item";
