@media (max-width: $screen-sm) {
    // sm-view
    .fast-filter-block {
        margin: 0 0 11px
    }

    .fast-filter {
        .fast-filter-holder { 
            .filter-element { 
                &.small {
                    .filter-element-holder { 
                        .filter-element-text { 
                            a {
                                font-size: 10px;
                            }
                        }
                    }
                }
                &.active {
                    .filter-element-holder { 
                        .filter-element-text { 
                            a {
                                padding: 10px 31px 10px 13px;
                            }
                        }
                    }
                }
                .filter-element-holder { 
                    .filter-element-text { 
                        a {
                            font-size: 12px;
                        }
                    }

                    .remove-action { 
                        right: 23px;
                        top: 12px;
                        a {
                            width: 12px;
                            height: 12px;
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
