.work-examples-block {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    padding: 50px 0 50px;
    .work-examples-block-holder {
        display: inline-block;
        width: 100%;
    }
}

.works-slider {
    .works-sldier-holder {
        &.slick-slider {
            .slick-list {
                overflow: visible;
            }
        }
    }
}

.work-example { 
    &.big {
        .work-example-holder {
            width: 843px;
        }
    }
	.work-example-holder {
        display: inline-block;
        width: 500px;
        height: 577px;
        margin-right: 10px;
		a { 
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            color: white;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            border-radius: 10px;
            overflow: hidden;
            &:hover {
                color: $brand-primary;
                .image { 
                    img {
                        transform: scale(1.1);
                        opacity: 1;
                        transition: all 300ms;
                    }
                }

                .text { 
                    .text-holder { 
                        .date { 
                            opacity: 0;
                            transition: all 300ms;
                        }

                        .desc { 
                            opacity: 0;
                            transition: all 300ms;
                        }
                        
                    }
                }
            }
			.image { 
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;
                background: $gray-base;
				img {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0.7;
                    transition: all 300ms;
				}
			}

			.text { 
                display: inline-block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                box-sizing: border-box;
                padding: 20px;
                .text-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    position: relative;
					.date { 
                        position: absolute;
                        left: 0;
                        top: 6px;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        transition: all 300ms;
                        color: white;
					}

					.desc { 
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        transition: all 300ms;
                        color: white;
						.desc-holder { 
                            width: calc(100% - 50px);
                            small {
                                display: inline-block;
                                font-size: 10px;
                                line-height: 0;
                                transform: translateY(-4px);
                            }
						}
					}

					.icon { 
                        position: absolute;
                        right: 0;
                        bottom: 0;
						.icon-holder { 
                            width: 30px;
                            height: 30px;
							svg { 
                                width: 100%;
                                height: 100%;
							}
						}
					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/index/work_examples";
@import "../../media/mobile/includes/index/work_examples";
