.works-list {
    display: inline-block;
    width: 100%;
    .works-list-holder {
        display: inline-block;
        width: calc(100% + 20px);
        margin-left: -10px;
        .work-example {
            float: left;
            width: 41.7%;
            box-sizing: border-box;
            padding: 0 10px;
            margin: 0 0 18px;
            &.long {
                width: 58.3%;
            }
            .work-example-holder {
                width: 100%;
                height: 577px;
            }
        }
    }
    .common-actions {
        margin: 18px 0 0;
    }
}

@import "../../media/tablet/includes/catalog/works_list";
@import "../../media/mobile/includes/catalog/works_list";
