@media (max-width: $screen-sm) {
    // sm-view
    .catalog-list-block {
        padding: 41px 0 40px;
        &.margin-down {
            padding: 0;
            margin: 0 0 73px;
        }
        .catalog-list-block-holder {
            .display-options { 
                margin: 3px 0 26px;
                .display-options-holder { 
                    .option { 
                        font-size: 12px;
                    }
                }
            }
        
        }
    }

    .catalog-list {
        .catalog-list-holder {
            width: 100%;
            margin: 0;
            .catalog-item {
                width: 100%;
                padding: 0;
                margin: 0 0 23px;
                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
