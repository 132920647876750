@media (max-width: $screen-lg) {
    // lg-md view
    .breadcrumbs {
        margin-top: 17px;
        margin-bottom: 17px;
    }
}

@media (max-width: $screen-md) {
    // md-view
    .breadcrumbs {
        margin-top: 2px;
        margin-bottom: 10px;
        .breadcrumbs-holder {
            ul.breadcrumbs-list {
                li {
                    font-size: 14px;
                    margin-right: 5px;
                }
            }
        }
    }
}
