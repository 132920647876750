.items-slider-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 94px;
    .items-slider-block-holder {
        display: inline-block;
        width: 100%;
    }
}

.items-slider {
    .items-slider-holder {
        width: calc(100% + 20px);
        margin-left: -10px;
        .catalog-item {
            box-sizing: border-box;
            padding: 0 10px;
        }
    }
}

@import "../../media/tablet/includes/catalog/item_slider";
@import "../../media/mobile/includes/catalog/item_slider";
