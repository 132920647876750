@media (max-width: $screen-sm) {
    // sm-view
    .text-block {
        .text-block-holder {
            p {
                font-size: 12px;
                margin: 0 0 15px;
                line-height: 142%;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
