.kits-block {
    display: inline-block;
    width: 100%;
    margin: 79px 0 50px;
    .kits-block-holder {
        display: inline-block;
        width: 100%;
        .heading {
            display: inline-block;
            width: 100%;
            margin: 0 0 32px;
        }
    }
}

.kit-grid {
    display: grid; 
    grid-template-columns: 49.3% 1fr 1fr; 
    grid-template-rows: auto; 
    grid-auto-rows: auto; 
    grid-gap: 1px;
    background: #EDEDED;
    grid-template-areas: 
    ". . ."; 
    border-bottom: 1px solid #EDEDED;
    box-sizing: border-box;
    .value {
        background-color: white;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center ;
        padding: 13px 20px;
        box-sizing: border-box;
    }

    .long {

    }
}

.compact-kit-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 1px;
    background: #EDEDED;
    .name {
        width: 100%;
        background: white;
        .name-text {
            display: flex;
            width: 100%;
            align-items: center;
            box-sizing: border-box;
            padding: 10px 15px;
            .text {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                width: calc(100% - 18px);
            }
           
            .info {
                width: 18px;
                height: 18px;
            }
        }

        .info-popup-text {
            float: left;
            width: 100%;
            .text-popup {
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                border-top: 1px solid #EDEDED;
                padding: 15px;
                .text-popup-holder {
                    display: inline-block;
                    width: 100%;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }
        }
    }

    .value {
        width: 100%;
        background: white;
        .value-holder {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-auto-rows: auto;
            gap: 1px;
            background: #EDEDED;
            .kit-heading {
                display: flex;
                box-sizing: border-box;
                padding: 14px 15px;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                background: white;
                .kit-name {
                    width: 100%;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .kit-pricing {
                    width: 100%;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 17px;
                    color: $brand-primary;
                }
            }

            .kit-status {
                display: flex;
                align-items: center;
                justify-content: center;
                background: white;
            }
        }
    }
}

.kit-items-list { 
    display: inline-block;
    width: 100%;
    border: 1px solid #EDEDED;
    border-bottom: none;
    box-sizing: border-box;
    border-radius: 10px 0px 0px 0px;
	.kit-items-list-holder { 
        display: inline-block;
        width: 100%;
		.kit-heading { 
            float: left;
            width: 100%;
            box-sizing: border-box;
			.kit-heading-holder { 

				.value { 
                    text-align: center;
                    flex-wrap: wrap;
                    padding: 10px 20px;
					.text { 
                        width: 100%;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
					}

                    .price {
                        width: 100%;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 16px;
                        color: $brand-primary;
                        margin: 1px 0 0;
                    }
				}
			}
		}

		.kit-group { 
            float: left;
            width: 100%;
            &.open {
                .kit-group-holder { 
                    .kit-group-name {
                        .kit-group-name-holder { 
                            .icon {
                                svg {
                                    transform: rotate(180deg);
                                    transition: all 300ms;
                                }
                            }
                        }
                    }

                    .kit-group-contains {
                        height: auto;
                        transition: all 300ms;
                        overflow: visible;
                    }
                }
            }
			.kit-group-holder { 
                display: inline-block;
                width: 100%;
				.kit-group-name { 
                    float: left;
                    width: 100%;
                    background-color: #F9F9F9;
                    border-bottom: 1px solid #EDEDED;
                    box-sizing: border-box;
					.kit-group-name-holder { 
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 11.5px 20px;
                        &:hover {
                            cursor: pointer;
                        }
						.text { 
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
						}

						.icon { 
                            margin-left: 15px;
                            width: 12px;
                            height: 8px;
							svg { 
                                transition: all 300ms;
							}
						}
					}
				}

				.kit-group-contains { 
                    float: left;
                    width: 100%;
                    height: 0px;
                    overflow: hidden;
                    transition: all 300ms;
					.kit-group-contains-holder { 
                        display: inline-block;
                        width: 100%;
						.kit-item { 

							.kit-item-holder { 

								.value {
                                    justify-content: center;
									.text { 
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 140%;
                                        width: calc(100% - 35px);
                                        text-align: left;
									}

									.info { 
                                        margin-left: 15px;
                                        width: 20px;
                                        height: 20px;
										a { 
                                            display: inline-block;
                                            width: 100%;
                                            height: 100%;
											.icon { 
                                                width: 100%;
                                                height: 100%;
												svg { 

												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.info.info-popup {
    a {
        display: block;
        position: relative;
        color: $gray-base;
        &:hover, &:focus {
            .text-popup {
                opacity: 1;
                padding: 15px 0 0;
                pointer-events: all;
                touch-action: auto;
                transition: all 300ms;
            }
        }
        .icon {

        }

        .text-popup {
            position: absolute;
            z-index: 1001;
            left: 50%;
            transform: translateX(-50%);
            top: 100%;
            width: 500px;
            height: auto;
            opacity: 0;
            transition: all 300ms;
            pointer-events: none;
            touch-action: none;
            padding: 0 0 0;
            .text-popup-holder {
                display: inline-block;
                width: 100%;
                box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
                border-radius: 10px;
                background: white;
                box-sizing: border-box;
                padding: 20px 25px;
                font-family: Manrope;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }
    }
}

.compact-kit-items-list {
    float: left;
    width: 100%;
    .compact-kit-items-list-holder {
        float: left;
        width: 100%;
        border: 1px solid #EDEDED;
        border-bottom: none;
        box-sizing: border-box;
        border-radius: 10px 10px 0px 0px;
        .kit-group {
            width: 100%;
            float: left;
            &.open {
                .kit-group-holder {
                    .kit-group-contains {
                        height: auto;
                        transition: all 300ms;
                    }
                }
            }
            .kit-group-holder {
                width: 100%;
                float: left;
                .kit-group-name {
                    width: 100%;
                    float: left;
                    background: $bg-secondary;
                    border-bottom: 1px solid #EDEDED;
                    .kit-group-name-holder {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 15px;
                        .text {
                            width: calc(100% - 17px);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        .icon {
                            margin-left: 5px;
                            width: 12px;
                            height: 7px;
                            .svg {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .kit-group-contains {
                    width: 100%;
                    height: 0;
                    overflow: hidden;
                    float: left;
                    transition: all 300ms;
                    .kit-group-contains-holder {
                        width: 100%;
                        float: left;
                        .kit-item {
                            width: 100%;
                            float: left;
                            border-bottom: 1px solid #EDEDED;
                            &.open-info {
                                .kit-item-holder  {
                                    .name {
                                        .name-text {
                                            .info {
                                                background: #EDEDED;
                                            }
                                        }

                                        .info-popup-text { 
                                            height: auto;
                                            transition: all 300ms;
                                        }
                                    }

                                    .value {
                                        .value-holder {
                                            .kit-heading {
                                                .kit-pricing {
                                                    height: 17px;
                                                    opacity: 1;
                                                    transition: all 300ms;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .kit-item-holder  {
                                .name {
                                    
                                    .name-text {
                                        .text {

                                        }

                                        .info {
                                            border-radius: 50%;
                                        }
                                    }

                                    .info-popup-text {
                                        height: 0px;
                                        overflow: hidden;
                                        transition: all 300ms;
                                    }
                                }

                                .value {
                                    .value-holder {
                                        .kit-heading {
                                            .kit-name {

                                            }

                                            .kit-pricing {
                                                height: 0px;
                                                opacity: 0;
                                                overflow: hidden;
                                                transition: all 300ms;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/detail_kits";
@import "../../media/mobile/includes/catalog/detail_kits";
