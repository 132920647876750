@media (max-width: $screen-lg) {
    // lg-md view
    .works-list {
        .works-list-holder {
            .work-example {
                width: 55%;
                &.long {
                    width: 45%;
                }
            }
        }
        .common-actions {
            margin: 20px 0 0;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .works-list {
        .works-list-holder {
            width: calc(100% + 10px);
            margin-left: -5px;
            .work-example {
                width: 50%;
                padding: 0 5px;
                margin: 0 0 8px;
                &.long {
                    width: 50%;
                }
                .work-example-holder {
                    height: 400px;
                }
            }
        }
        .common-actions {
            margin: 28px 0 0;
        }
    }
}
