@media (max-width: $screen-lg) {
    // lg-md view
    .items-slider-block {
        margin: 0 0 74px;
    }
}

@media (max-width: $screen-md) {
    // md-view
    .items-slider-block {
        margin: 0 0 55px;
    }

    .items-slider {
        .items-slider-holder {
            width: calc(100% + 10px);
            margin-left: -5px;
            .catalog-item {
                padding: 0 5px;
            }
        }
    }
}
