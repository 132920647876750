@media (max-width: $screen-sm) {
    // sm-view
    .work-examples-block {
        padding: 40px 0 40px;
        .work-examples-block-holder {
            .common-action {
                margin: 22px 0 0;
            }
        }
    }

    .work-example { 
        &.big {
            .work-example-holder {
                width: 230px;
            }
        }
        .work-example-holder {
            height: 331px;
            width: 260px;
            a {
                font-size: 12px;
                .text { 
                    padding: 13px 15px;
                    .text-holder { 
                        .date {
                            font-size: 16px;
                        }

                        .icon { 
                            .icon-holder { 
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
