@media (max-width: $screen-sm) {
    // sm-view
    .filter-block {
        margin: 0 0 24px;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
