.catalog-detail-block {
    display: inline-block;
    width: 100%;
    margin: 0 0 49px;
    .catalog-detail-block-holder {
        display: inline-block;
        width: 100%;
    }
}

.detail-image { 
    display: inline-block;
    width: 100%;
    margin: 0 0 30px;
	.detail-image-holder { 
        display: grid; 
        grid-template-columns: 57.8% 1fr 1fr; 
        grid-template-rows: 290px 290px; 
        grid-auto-rows: 290px;
        gap: 20px 20px; 
        grid-template-areas: 
          "big . ."
          "big . ."; 
        .big { grid-area: big; }

		.image { 
            display: inline-block;
            width: 100%;
            height: 100%;
			.image-holder { 
                display: inline-block;
                width: 100%;
                height: 100%;
				img { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 10px;
				}
                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }
			}
		}
	}
}

.detail-info {
    display: inline-block;
    width: 100%;
    .detail-info-holder {
        display: inline-block;
        width: 100%;
        .pricing { 
            display: inline-block;
            width: 100%;
            border-radius: 10px;
            background-color: $bg-secondary;
            margin: 0 0 20px;
            .pricing-holder { 
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                box-sizing: border-box;
                padding: 25px 25px;
                .price { 
                    
                    .value { 
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                    }
        
                    .note { 
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        color: $gray-dark;
                        margin: 5px 0 0;
                    }
                }
        
                .action { 
                    
                    .btn { 
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        width: 194px;
                        height: 55px;
                        border-radius: 10px;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }
            }
        }

        .params.uneven {
            .params-holder { 
                .params-list { 
                    grid-template-columns: 40.9% 1fr 1fr 1fr;
                }
            }
        } 
        .params { 
            display: inline-block;
            width: 100%;
            margin: 0 0 20px;
            .params-holder { 
                display: inline-block;
                width: 100%;
                .params-list { 
                    display: grid; 
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    grid-auto-rows: 183px; 
                    gap: 20px 20px; 
                    .param { 
                        width: 100%;
                        height: 100%;
                        .param-holder { 
                            width: 100%;
                            height: 100%;
                            background: $bg-secondary;
                            border-radius: 10px;
                            padding: 25px 30px;
                            box-sizing: border-box;
                            .icon { 
                                width: 100%;
                                margin: 0 0 23px;
                                .icon-holder { 
        
                                    svg { 
                                        width: 50px;
                                        height: 50px;
                                    }
                                }
                            }
        
                            .text { 
                                width: 100%;
                                .value { 
                                    width: 100%;
                                    font-size: 28px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: normal;
                                }
        
                                .note { 
                                    width: 100%;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .desc {
                                .desc-holder {
                                    p {
                                        font-size: 16px;
                                        color: $gray-dark;
                                        margin: 0 0 1px;
                                    }

                                    h2, .h2 {
                                        margin: 0 0 14px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .column-info { 
            display: inline-block;
            width: 100%;
            margin: 0 0 20px;
            .column-info-holder { 
                display: grid; 
                grid-template-columns: 1fr 1fr;
                grid-auto-rows: auto; 
                gap: 20px 20px; 
                .col { 
                    width: 100%;
                    height: 100%;
                    .col-holder { 
                        width: 100%;
                        height: 100%;
                        background: $bg-secondary;
                        border-radius: 10px;
                        padding: 30px 30px;
                        box-sizing: border-box;
                        .text { 
                            width: 100%;
                            h3, .h3 { 
                                margin: 0 0 30px;
                            }
        
                            p { 
                                font-size: 16px;
                                margin: 0 0 14px;
                                &:last-of-type {
                                    margin: 0;
                                }
                            }

                            .action {
                                width: 100%;
                                margin: 28px 0 0;
                                .btn {
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    padding: 15px 25px;
                                }
                            }
                        }

                        .image {
                            width: 100%;
                            height: 100%;
                            a {
                                display: flex;
                                width: 100%;
                                height: 100%;
                                align-items: center;
                                justify-content: center;
                                img {
                                    width: 438px;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
        }
    } 
}

@import "../../media/tablet/includes/catalog/detail";
@import "../../media/mobile/includes/catalog/detail";
