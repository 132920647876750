@media (max-width: $screen-lg) {
    // lg-md view
    .work-examples-block {
        padding: 40px 0 42px;
    }

    .work-example { 
        .work-example-holder {
            height: 497px;
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
    .work-examples-block {
        padding: 40px 0 35px;
    }

    .work-example { 
        .work-example-holder {
            height: 381px;
            a {
                font-size: 14px;
                .text { 
                    .text-holder { 
                        .date {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}
