@media (max-width: $screen-sm) {
    // sm-view
    .works-list {
        .works-list-holder {
            width: 100%;
            margin-left: 0;
            .work-example {
                width: 100%;
                padding: 0;
                margin: 0 0 13px;
                &.long {
                    width: 100%;
                }
                .work-example-holder {
                    height: 331px;
                }
            }
        }
        .common-actions {
            margin: 23px 0 0;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
